import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";
import Container from "../Container";

const Section = ({
  children,
  containerSx,
  outerChildren,
  scrollToID,
  scrollToIDSx,
  sx,
  ...rest
}) => {
  const sectionStyled = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    mb: [6, null, null, 8, null],
    mt: [6, null, null, 8, null],
    pb: [6, null, null, 8, null],
    pt: [6, null, null, 8, null],
    position: "relative",
    // zIndex: 1,
    ...sx,
  };
  return (
    <Box as="section" sx={sectionStyled} {...rest}>
      {scrollToID && (
        <Box
          id={scrollToID}
          sx={{
            position: "absolute",
            top: -5,
            ...scrollToIDSx,
          }}
        />
      )}
      {outerChildren && outerChildren}
      <Container sx={{ ...containerSx }}>{children}</Container>
    </Box>
  );
};


Section.propTypes = {
  children: PropTypes.node.isRequired,
  containerSx: PropTypes.shape({}),
  outerChildren: PropTypes.node,
  scrollToID: PropTypes.string,
  scrollToIDSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

Section.defaultProps = {
  containerSx: {},
  outerChildren: null,
  scrollToID: "",
  scrollToIDSx: {},
  sx: {},
};


export default Section;
