import { rgba } from "polished";

const nisargSecondary = "#E98F7B";
const nisargPrimary = "#8AA487";
const nisargPrimaryLight = "#DDE4DA";
const black = "#161616";
const white = "#F4F4F4";
const lightGray = "#C4C4C4";

const breakpoints = ["544px", "710px", "992px", "1200px", "1400px"];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const blackTransparent01 = rgba(`${black}`, 0.1);
const blackTransparent06 = rgba(`${black}`, 0.06);
const blackTransparent04 = rgba(`${black}`, 0.04);
const blackTransparent25 = rgba(`${black}`, 0.25);
const transitionAll = "all 0.35s ease-in-out";

const buttonStyled = {
  common: {
    "&:hover": {
      bg: "primary",
      borderColor: "primary",
      boxShadow: 2,
      color: "white",

    },
    // bg: "primary",
    alignItems: "center",
    borderRadius: "12px 12px 0px 12px",
    borderStyle: "solid",
    borderWidth: "2px",
    color: "white",
    display: "inline-flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "40px",
    justifyContent: "center",
    letterSpacing: 0,
    lineHeight: 2,
    minHeight: "calc(0.5rem * 2 + 1.6em)",
    minWidth: "213px",
    px: 2,
    py: 1,
    textTransform: "uppercase",
    transition: transitionAll,
  },
  disabled: {
    bg: "primaryTransparent",
    border: "none",
    boxShadow: 0,
    pointerEvents: "none",
  },
  icon: {
    minWidth: "40px",
    pb: 2,
    pl: 2,
    pr: 2,
    pt: 2,
    borderRadius: "50%",
  },
  outline: {
    borderColor: "primary",
    color: "white",

  },
  // secondary: {
  //   common: {
  //     "&:hover": {
  //       bg: "secondaryLight",
  //       borderColor: "secondaryLight",
  //       boxShadow: 2,
  //       color: "secondary",
  //     },
  //     bg: "secondaryMedium",
  //     borderColor: "secondaryMedium",
  //     borderWidth: "1px",
  //     boxShadow: 1,
  //     color: "secondaryLight",
  //   },
  //   disabled: {
  //     bg: "white",
  //     boxShadow: 0,
  //     color: "primaryTransparent",
  //   },
  //   solid: {
  //     bg: "secondaryMedium",
  //   },
  // },
  solid: {
    bg: "primary",
    color: "white",
  },
  white: {
    bg: "white",
    color: "primary",
  },
};

export default {
  baseFontSizesForBreakpoint: ["5vw", "4vw", "2.5vw", "1.5vw", "18px"],
  breakpoints,
  colors: {
    white,
    black,
    body: white,
    error: nisargSecondary,
    primary: nisargPrimary ,
    primaryLight: nisargPrimaryLight,
    primaryDark: black,
    primaryTransparent: lightGray,
    success: nisargSecondary,

    text: {
      link: white,
      primary: black,
      secondary: white,
      label: nisargPrimary ,
      secondaryHeading: white,
      heading: nisargPrimary ,
    },
  },
  filter: [
    `drop-shadow(0px 0px 0px transparent)`,
    `drop-shadow(0px 1px 3px ${blackTransparent01}) drop-shadow(0px 1px 2px ${blackTransparent06})`,
    `drop-shadow(0px 10px 15px ${blackTransparent01}) drop-shadow(0px 4px 6px ${blackTransparent04})`,
    `drop-shadow(0px 25px 50px ${blackTransparent25})`,
  ],
  fonts: {
    primary: '"Montserrat", sans-serif',
    pera: '"Roboto Condensed", sans-serif',
  },
  fontSizes: ["12px", "16px", "20px", "24px", "28px", "32px", "40px"],
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  letterSpacings: ["0.05em", "0.1em", "0.125em"],
  lineHeights: [
    "52px",
    "49px",
    "44px",
    "40px",
    "29px",
    "24px",
    "19.5px",
    "17px",
  ],

  radii: [0, "6px", "8px", "12px", "32px", "9999px"],
  shadows: [
    "none",
    `0px 1px 3px ${blackTransparent01}, 0px 1px 2px ${blackTransparent06}`,
    `0px 10px 15px -3px ${blackTransparent01}, 0px 4px 6px -2px ${blackTransparent04}`,
    `0px 25px 50px -14px ${blackTransparent25}`,
  ],
  sizes: {
    iconWidth: {
      lg: "84px",
      md: "64px",
      sm: "24px",
      xl: "120px",
    },
    maxWidths: {
      button: "14rem",
      lg: "960px",
      md: "710px",
      sm: "544px",
      xl: "1200px",
    },
  },
  space: [
    "0", // 0 0px
    "0.25rem", // 1  4px
    "0.375rem", // 2 6px
    "0.5rem", // 3  8px
    "0.75rem", // 4  12px
    "1rem", // 5  16px
    "1.25rem", // 6  20px
    "2rem", // 7  32px
    "3.40rem", // 8  54.4px
    "5.25rem", // 9  84px
    "8.5rem", // 10  136px
    "5.05555rem", // 11  80.88px
    "7.05555rem", // 12  112.889px
    "2.75rem", // 13    44px
    "0.4rem", //14  -8px
    "1.5rem", //15 -24px
    "2.5rem", //16 38px
    "14px",
  ],
  variants: {
    buttons: {
      outline: {
        ...buttonStyled.common,
        "&:hover": {
          ...buttonStyled.common["&:hover"],
          bg: "white",
          borderColor: "primary",
        },
        ...buttonStyled.outline,
        disabled: {
          ...buttonStyled.common,
          ...buttonStyled.disabled,
          ...buttonStyled.outline,
        },
        icon: {
          ...buttonStyled.common,
          "&:hover": {
            ...buttonStyled.common["&:hover"],
            bg: "white",
            borderColor: "primary",
          },
          ...buttonStyled.outline,
          ...buttonStyled.icon,
        },
      },
      primary: {
        ...buttonStyled.common,
        "&:hover": {
          ...buttonStyled.common["&:hover"],
        },
        ...buttonStyled.outline,
        bg: "primary",
        borderColor: "primary",
        color: "white",
        disabled: {
          ...buttonStyled.common,
          ...buttonStyled.disabled,
        },
        icon: {
          ...buttonStyled.common,
          "&:hover": {
            ...buttonStyled.common["&:hover"],
          },
          ...buttonStyled.outline,
          bg: "primary",
          borderColor: "primary",
          color: "white",
          ...buttonStyled.icon,
        },
      },
      // secondary: {
      //   ...buttonStyled.common,
      //   ...buttonStyled.secondary.common,
      //   disabled: {
      //     ...buttonStyled.common,
      //     ...buttonStyled.secondary.common,
      //     ...buttonStyled.secondary.disabled,
      //   },
      //   icon: {
      //     ...buttonStyled.common,
      //     ...buttonStyled.secondary.common,
      //     ...buttonStyled.icon,
      //   },
      //   outline: {
      //     ...buttonStyled.common,
      //     ...buttonStyled.secondary.common,
      //     disabled: {
      //       ...buttonStyled.common,
      //       ...buttonStyled.disabled,
      //       ...buttonStyled.secondary.common,
      //     },
      //     icon: {
      //       ...buttonStyled.common,
      //       ...buttonStyled.secondary.common,
      //       ...buttonStyled.icon,
      //     },
      //   },
      // },
    },
    error: {
      boxShadow: 0,
      fontSize: "14px",
      fontWeight: "regular",
      left: 0,
      ml: 4,
      mt: 1,
    },
    headings: {
      primary: {
        color: "primary",
      },
      secondary: {
        color: "  white",
      },
    },
    inputs: {
      error: {
        "&:focus": { borderColor: "error", boxShadow: 0 },
        bg: "white",
        fontSize: "12px",
        borderColor: "error",
        borderRadius: "12px",
        borderStyle: "solid",
        borderWidth: "3px",
        minHeight: `calc(0.5rem * 2 + 1.6em)`,
        px: 5,
        py: 1,
      },
      primary: {
        "&:focus": { borderColor: "primary", boxShadow: 2 },
        bg: "white",
        borderColor: "primary",
        borderRadius: "12px 12px 0px 12px",
        borderStyle: "solid",
        fontWeight: "regular",
        borderWidth: "3px",
        placeholderColor: "rgba(primary, 0.5)",
        color: "black",
        fontSize: 1,
        minHeight: "calc(0.5rem * 2 + 1.6em)",
        px: 5,
        py: 1,
      },
    },

    label: {
      color: "text.label",
      fontSize: 1,
      fontWeight: "regular",
      fontFamily: "fonts.primary",
      mb: 2,
      ml: 1,
      textTransform: "capitalize",
    },
    sections: {
      dark: {
        bg: "primary",
        color: "white",
      },
      // light: {
      //   bg: "secondaryLight",
      //   color: "black",
      // },
      header: {
        bg: "white",
        color: "primary",
      },
      primary: {
        bg: "white",
        color: "black",
      },
      primaryLight: {
        bg: "primaryLight",
        color: "black",
      },
      white: {
        bg: "white",
        color: "black",

      },
    },
  },
  zIndices: [0, 1, 10, 20, 50, 100, 9999],
};
