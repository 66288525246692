import React from "react";
import PropTypes from "prop-types";
import NavBar from "../../components/Navbar";

const Navigation = ({ navLinks, toggled, ...rest }) => {
  const navBarStyled = {
    a: {
      "&:hover, &.active": {
        color: "primary",
        bg: "#D3D3D3",
      },

      alignItems: "center",
      cursor: "pointer",
      color: "primary",
      display: "inline-flex",
      fontSize: 0,
      fontWeight: "bold",
      pl: 4,
      pr: 4,
      py: 4,
      zIndex: 3,
    },
    alignItems: "center",
    bg: ["white", null, null, "unset"],
    display: "flex",
    flex: "auto",
    justifyContent: "center",
    left: ["0", null, null, "unset"],
    li: {
      "& > ul": {
        a: {
          color: ["grays.3", null, null, "inherit"],
          fontSize: "0.7rem",
          fontWeight: "medium",
        },
        "&hover": {
          color: ["primary", null, null, "secondary"],
        },
        color: "black",
        bg: "primaryLight",
        borderRadius: [null, null, null, 1],
        boxShadow: 1,
        display: "flex",
        flexDirection: "column",
        left: [null, null, null, "0"],
        li: {
          textTransform: "capitalize",
        },
        maxHeight: 0,
        mb: "-1px",
        minWidth: ["100%", null, null, "15rem"],
        opacity: 0,
        overflow: "hidden",
        p: 0,
        position: [null, null, null, "absolute"],
        top: [null, null, null, "100%"],
        transition: "max-height .55s, opacity .55s",
        ul: {
          left: [null, null, null, "100%"],
          top: [null, null, null, 0],
        },
        zIndex: 2,
      },
      "&.active": {
        color: "priary",
      },
      "&.open, &:hover": {
        "& > .toggle-control": {
          transform: "rotate(180deg)",
          transition: "transform .55s",
        },
        "& > ul": {
          color: "black",
          // bg: ["dark", null, null, "white"],
          boxShadow: 2,
          li: {
            "&.open, &:hover": {
              "& > .toggle-control": {
                transform: ["rotate(180deg)", null, null, "rotate(-90deg)"],
              },
              bg: [null, null, null, "rgba(0, 0, 0, 0.1)"],
              color: [null, null, null, "primary"],
            },
          },
          maxHeight: "30rem",
          opacity: 1,
          overflow: "visible",
        },
        bg: ["grays.6", null, null, "transparent"],
        color: "primary",
      },
      "&:not(:last-child)": {
        borderBottom: [".01rem solid", null, null, 0],
        borderColor: "rgba(255, 255, 255, .03)",
      },
      color: "black",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      pr: [null, null, null, 2],
      textTransform: "uppercase",
    },
    maxHeight: [`${toggled ? "50rem" : 0}`, null, null, "none"],
    overflow: ["hidden", null, null, "visible"],
    position: ["absolute", null, null, "static"],
    right: ["0", null, null, "unset"],
    top: ["100%", null, null, "unset"],
    transition: "maxHeight .55s ease-in-out",
    ul: {
      alignSelf: "center",
      display: "flex",
      flex: 1,
      flexDirection: ["column", null, null, "unset"],
      justifyContent: "center",
      p: [4, null, null, 0],
    },
  };
  return <NavBar navLinks={navLinks} sx={navBarStyled} {...rest} />;
};

Navigation.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggled: PropTypes.bool,
};

Navigation.defaultProps = {
  toggled: false,
};

export default Navigation;
