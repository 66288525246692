import React, { createContext } from "react";
import { useState } from "react";

export const languageContext = createContext({
  language: "en",
  toggleLanguage: () => {},
});

const GlobalContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "gu" : "en");
  };
  return (
    <languageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </languageContext.Provider>
  );
};

export default GlobalContextProvider;
