import React from "react";
import Section from "../Section";
import Box from "../Box";
import { footerLinks, socialMedia } from "../../data/page";
import Icon from "../Icon";
import Divider from "../Divider";
import Copyright from "../Copyright";
import {
  SCHOOL_MOBILE_NUMBER,
  SCHOOL_EMAIL,
  SCHOOL_LOCATION,
} from "../../utilities/constants";
import Image from "../Image";
import LinkTo from "../LinkTo";

const Footer = () => {
  const containerSx = {
    display: "flex",
    flexDirection: "column",
    fontSize: 0,
    pb: [4, 5, 6],
    pt: [4, 5, 6],
  };
  const linkWrapperStyled = {
    alignItems: "start",
    display: "flex",
    flexDirection: ["column", null, "column"],
    justifyContent: "start",
  };
  const contentSx = {
    color: "white",
    display: "inline-block",
    fontFamily: "pera",
    fontSize: "16px",
    fontWeight: "light",
    p: 2,
  };
  const basicflex = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
  };
  return (
    <Section
      as="footer"
      containerSx={containerSx}
      sx={{ mb: 0, mt: 0, pb: 0, pt: 0 }}
      variant="sections.dark"
    >
      <Box
        sx={{
          alignItems: "start",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          width={["100%", "null", "auto"]}
          mb={["25px", "null", "0px"]}
          sx={{
            ...basicflex,
            flexDirection: ["row", "row", "column"],
            justifyContent: ["space-evenly", "space-evenly", "center"],
            alignItems: "center",
          }}
        >
          <Box
            as="a"
            href="/"
            sx={{
              ...linkWrapperStyled,
            }}
          >
            <Icon svg="Nsg-logo-white" sx={{ p: 1, mr: [4, null, null] }} />
          </Box>
          <Box
            as="p"
            sx={{
              lineHeight: "28px",
              maxWidth: ["190px", null, "250px"],
             
            }}
          >
            {SCHOOL_LOCATION.LOCATION}
          </Box>
        </Box>

        <Box
          width={["100%", "100%", "auto"]}
          mb={["25px", "15px", "0px"]}
          sx={{ ...basicflex, alignItems: "center" }}
        >
          <Box
            sx={{
              ...contentSx,
              color: "primaryMedium",
              fontSize: "20px",
              fontWeight: "semiBold",
            }}
          >
            Category
          </Box>
          {footerLinks && (
            <Box
              as="ul"
              sx={{
                ...linkWrapperStyled,
                listStyle: "none",
              }}
            >
              {footerLinks.map((item, index) => (
                <Box
                  key={index}
                  as="li"
                  sx={{ listStyle: "none", ...contentSx }}
                >
                  <LinkTo
                    hoverSx={{
                      color: "primaryLight",
                    }}
                    sx={{
                      "&.active": {
                        color: "primaryLight",
                      },
                      ...contentSx,
                      p: 0,
                    }}
                    to={item.path}
                  >
                    {item.label}
                  </LinkTo>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            ...basicflex,
            width: ["100%", "100%", "auto"],
            flexDirection: ["row", "row", "column"],
            mt: ["25px", "15px", "0px"],
          }}
        >
          <Box sx={{ ...basicflex, width: ["auto", "50%", "100%"] }}>
            <Box
              sx={{
                ...contentSx,
                color: "primaryMedium",
                fontSize: "20px",
                fontWeight: "semiBold",
              }}
            >
              Contact
            </Box>
            <Box
              sx={{
                ...contentSx,
              }}
            >
              {SCHOOL_MOBILE_NUMBER.CONTACT_NUMBER}
            </Box>
            <Box
              sx={{
                ...contentSx,
              }}
            >
              {SCHOOL_EMAIL.CONTACT}
            </Box>
          </Box>
          <Box sx={{ ...basicflex, mt: [null, null, 4], width: ["auto", "50%", "100%"] }}>
            <Box
              sx={{
                ...contentSx,
                color: "primaryMedium",
                fontSize: "20px",
                fontWeight: "semiBold",
              }}
            >
              Socials
            </Box>
            <Box
              sx={{
                ...basicflex,
                pl: 2,
                flexDirection: "row",
              }}
            >
              {socialMedia.map((item, index) => (
                <Box
                  key={index}
                  as="a"
                  href={item.link}
                  title={item.icon}
                  target="_blank"
                  to={null}
                  sx={{
                    ":hover": {
                      svg: {
                        fill: "primaryLight",
                      },
                    },
                    color: "white",
                    cursor: "pointer",
                    mt: 2,
                    mr: 4,
                  }}
                >
                  <Image svgIcon={item.icon} />
                </Box>
              ))}
            </Box>

            {/* <LinkTo
              to={"/terms-conditions"}
              sx={{
                p: [2, null, 2],
                mt: [null, null, 3],
                fontSize: "16px",
              }}
            >
              T & C
            </LinkTo> */}
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 6,
        }}
      >
        <Divider />
        <Copyright
          append="Nisrag Pathshala. All Rights Reserved."
          sx={{
            ...contentSx,
            p: 0,
            textAlign: "center",
          }}
        />
      </Box>
    </Section>
  );
};

export default Footer;
