import React from "react";
import AppBase from "../AppBase";
import Box from "../Box";
import theme from "../../themes/default";
import Footer from "../Footer";
import Header from "../Header";
import PropTypes from "prop-types";
import "./css/slick.css";
import "./css/slick-theme.css";

const Layout = ({ children, location: { pathname }, ...rest }) => {
  return (
    <AppBase theme={theme}>
      <Box
        sx={{
          left: 0,
          overflowX: "hidden",
          position: "relative",
          top: 0,
          width: "100%",
        }}
      >
        <Header />
        <Box as="main">
          {typeof children === "function" ? children(rest) : children}
        </Box>

        <Footer />
      </Box>
    </AppBase>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default Layout;
