import React from "react";
import Box from "../Box";
import PropTypes from "prop-types";
import facebook from "../../../static/assets/facebook.svg";
import instagram from "../../../static/assets/instagram.svg";
import twitter from "../../../static/assets/twitter.svg";
import linkedin from "../../../static/assets/linkedin.svg";
import close from "../../../static/assets/close.svg";
import facebookcolor from "../../../static/assets/facebook-color.svg";
import instagramcolor from "../../../static/assets/instagram-color.svg";
import twittercolor from "../../../static/assets/twitter-color.svg";
import linkedincolor from "../../../static/assets/linkedin-color.svg";
import chevronDown from "../../../static/assets/chevron-down.svg";
import demo from "../../../static/assets/demo.svg";
import arrow from "../../../static/assets/arrow.svg";
import Nsglogowhite from "../../../static/assets/Nsg-logo-white.svg";
import Nsglogocolor from "../../../static/assets/Nsg-logo-color.svg";
import NsglogoGreen from "../../../static/assets/Nsg-logo-green.svg";
import address from "../../../static/assets/address.svg";
import email from "../../../static/assets/email.svg";
import call from "../../../static/assets/call.svg";
import contact from "../../../static/assets/email.svg";
import mapPin from "../../../static/assets/map-pin.svg";
import cardimage from "../../../static/assets/cardimage.svg";
import mvimage from "../../../static/assets/mvimage.svg";
import careerBanner from "../../../static/assets/career-banner.svg";
import chevronDownColor from "../../../static/assets/chevron-down-color.svg";
import share from "../../../static/assets/share.svg";
import ethics from "../../../static/assets/ethics.svg";
import environmental from "../../../static/assets/environmental.svg";
import education from "../../../static/assets/education-learning.svg";

const svgMap = {
  arrow: arrow,
  demo: demo,
  "Nsg-logo-white": Nsglogowhite,
  "Nsg-logo-color": Nsglogocolor,
  address: address,
  email: email,
  call: call,
  mapPin: mapPin,
  "Nsg-logo-green": NsglogoGreen,
  "chevron-down": chevronDown,
  close: close,
  share: share,
  "contact-banner": contact,
  instagram: instagram,
  facebook: facebook,
  twitter: twitter,
  linkedin: linkedin,
  "facebook-color": facebookcolor,
  "twitter-color": twittercolor,
  "instagram-color": instagramcolor,
  "linkedin-color": linkedincolor,
  cardimage: cardimage,
  mvimage: mvimage,
  "career-banner": careerBanner,
  chevronDownColor: chevronDownColor,
  ethics: ethics,
  environmental: environmental,
  "education-learning": education,
};

const Icon = ({ svg, ...rest }) => {
  const SvgComponent = svgMap[svg];

  return (
    <Box {...rest}>
      <SvgComponent />
    </Box>
  );
};

Icon.propTypes = {
  svg: PropTypes.oneOf([
    "arrow",
    "demo",
    "Nsg-logo-color",
    "Nsg-logo-white",
    "team-meeting-about",
    "chevron-down",
    "close",
    "contact-banner",
    "mapPin",
    "instagram",
    "facebook",
    "share",
    "twitter",
    "chevron-down",
    "linkedin",
    "cardimage",
    "mvimage",
    "careerBanner",
    "chevronDown",
    "chevronDownColor",
    "ethics",
    "Nsg-logo-green",
    "environmental",
    "education-learning",
  ]).isRequired,
};

export default Icon;
