import { createGlobalStyle } from "styled-components";

const StyledGlobal = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
    
  html {
    height: 100%;
    overflow-x: hidden;
    background-color: ${(p) => p.theme.colors.body};
    color: ${(p) => p.theme.colors.text.primary};
    font-family: ${(p) => p.theme.fonts.primary};
    font-size: ${(p) => p.theme.baseFontSizesForBreakpoint[0]};
    font-weight: ${(p) => p.theme.fontWeights.label};

    @media (min-width: ${(p) => p.theme.breakpoints[0]}) {
      font-size: ${(p) => p.theme.baseFontSizesForBreakpoint[1]};
    }

    @media (min-width: ${(p) => p.theme.breakpoints[1]}) {
      font-size: ${(p) => p.theme.baseFontSizesForBreakpoint[2]};
    }

    @media (min-width: ${(p) => p.theme.breakpoints[2]}) {
      font-size: ${(p) => p.theme.baseFontSizesForBreakpoint[3]};
    }

    @media (min-width: ${(p) => p.theme.breakpoints[3]}) {
      font-size: ${(p) => p.theme.baseFontSizesForBreakpoint[4]};
    }
  }

  body {
    position: relative;
    width: 100%;
    margin: 0;
    max-width: 100%;
    min-height: 10vh;
    overflow-x: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    > div {
      &, > div {
        &, > div, main {
          display: flex;
          flex-direction: column;
          flex: auto;
        }
      }
    }
  }

  a {
    color: ${(p) => p.theme.colors.text.link};
    text-decoration: none;
  }

  button,
  input[type='button'],
  input[type='submit'] {
    padding: 0;
    border: none;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: none;
  }

  textarea {
    display: block;
    resize: vertical;
  }

  a,
  input,
  button,
  textarea {
    font: inherit;
    outline: none;

    &:focus,
    &:active {
      outline: none;
    }

    &[type='radio'],
    &[type='checkbox'] {
      margin: 0;
    }
  }

  figure,
  h1,
  h2,
  h3,
  h4,
  li,
  p,
  pre,
  ul {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${(p) => p.theme.colors.text.black};
    font-family: ${(p) => p.theme.fonts.primary};
    font-weight: ${(p) => p.theme.fontWeights.regular};
  }

  h1 {
    font-size: ${(p) => p.theme.fontSizes[6]};
    font-weight: ${(p) => p.theme.fontWeights.bold}
  }

  h2 {
    font-size: ${(p) => p.theme.fontSizes[5]};
    color: ${(p) => p.theme.colors.text.secondaryHeading};
    font-weight: ${(p) => p.theme.fontWeights.semiBold}
  }

  h3 {
    color: ${(p) => p.theme.colors.text.secondary};
    font-size: ${(p) => p.theme.fontSizes[3]};
    font-weight: ${(p) => p.theme.fontWeights.medium}
  }

  h4 {
    font-size: ${(p) => p.theme.fontSizes[2]};
    font-weight: ${(p) => p.theme.fontWeights.medium};
  }
  
  p {
    color: ${(p) => p.theme.colors.text.secondary};
    font-size: ${(p) => p.theme.fontSizes[1]};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-family: ${(p) => p.theme.fonts.primary};
    line-height: ${(p) => p.theme.lineHeights[5]};
  }

  hr {
    height: 1px;
    border: 0;
    background-color: ${(p) => p.theme.colors.border};
  }
  .toggle {
      --width: 80px;
      --height: calc(var(--width) / 2);

      position: relative;
      display: inline-block;
      width: var(--width);
      height: var(--height);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      border-radius: var(--height);
      cursor: pointer;
    }

    .toggle input {
      display: none;
    }

    .toggle .slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--height);
      background-color: lightgray;
      transition: all 0.4s ease-in-out;
    }

    .toggle .slider::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(var(--height));
      height: calc(var(--height));
      border-radius: calc(var(--height) / 2);
      background-color: white;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      transition: all 0.4s ease-in-out;
    }

    .toggle input:checked+.slider {
      background-color: #8AA487;
    }

    .toggle input:checked+.slider::before {
      transform: translateX(calc(var(--width) - var(--height)));
    }

    .toggle .labels {
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-family: sans-serif;
      transition: all 0.4s ease-in-out;
      overflow: hidden;
    }

    .toggle .labels::after {
      content: attr(data-off);
      position: absolute;
      top: 15%;
      align-items: center;
      right: 15px;
      color: black;
      opacity: 1;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      transition: all 0.4s ease-in-out;
    }

    .toggle .labels::before {
      content: attr(data-on);
      position: absolute;
      top: 15%;
      left: calc(var(--height) - var(--width) + 15px);
      color: white;
      opacity: 0;
      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
      transition: all 0.4s ease-in-out;
    }

    .toggle input:checked~.labels::after {
      opacity: 0;
      transform: translateX(calc(var(--width) - var(--height)));
    }

    .toggle input:checked~.labels::before {
      opacity: 1;
      transform: translateX(calc(var(--width) - var(--height)));
    }

`;

export default StyledGlobal;
