import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";

const Copyright = ({ sx, prePend, append }) => (
  <Box as="span" sx={sx}>
    {prePend}&nbsp;{new Date().getFullYear()}&nbsp;{append}
  </Box>
);

Copyright.propTypes = {
  append: PropTypes.string,
  prePend: PropTypes.string,
  sx: PropTypes.shape({}),
};

Copyright.defaultProps = {
  append: ``,
  prePend: `Copyright ©`,
  sx: {},
};

export default Copyright;
