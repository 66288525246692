import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Location } from "@reach/router";
import Box from "../Box";

const LinkTo = ({ to, children, sx, activeSx, hoverSx, ...rest }) => {
  const hasLocation = (location, path) => {
    const { pathname } = location;
    return pathname.includes(path);
  };
  const hoverStyled = {
    "&:hover": {
      ...hoverSx,
    },
  };
  const linkStyled = {
    ...sx,
    ...hoverStyled,
  };
  return (
    <Location>
      {({ location }) => (
        <Box
          activeClassName="active"
          as={Link}
          // partiallyActive
          sx={
            hasLocation(location, to)
              ? { ...linkStyled, ...activeSx }
              : linkStyled
          }
          to={to}
          {...rest}
        >
          {children}
        </Box>
      )}
    </Location>
  );
};

LinkTo.propTypes = {
  activeSx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  hoverSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  to: PropTypes.string,
};

LinkTo.defaultProps = {
  activeSx: {},
  hoverSx: {},
  sx: {},
  to: "/",
};

export default LinkTo;
