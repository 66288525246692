import React from "react";
import PropTypes from "prop-types";
import AspectRatio from "../AspectRatio";
import Box from "../Box";

const Hamburger = ({ innerSx, sx, children, toggled, onClick }) => {
  const toggledBarStyled = toggled
    ? {
        ":nth-child(1), :nth-child(4)": {
          bottom: "50%",
          left: "50%",
          top: "50%",
          transform: "scale(0)",
          width: "0",
        },
        ":nth-child(2)": {
          transform: "rotate(45deg)",
        },
        ":nth-child(3)": {
          transform: "rotate(-45deg)",
        },
      }
    : {};
  const hamburgerStyled = {
    "& span": {
      ":nth-child(1)": {
        top: 0,
      },
      ":nth-child(2), :nth-child(3)": {
        mt: "-0.7px",
        top: "50%",
      },
      ":nth-child(4)": {
        bottom: 0,
      },
      boxShadow: "inset 0 0 0 100px",
      display: "block",
      height: "2px",
      left: "0",
      opacity: "1",
      position: "absolute",
      transform: "rotate(0)",
      transition: ".25s ease-in-out",
      color: "black",
      width: "100%",
      bg: "black",
      ...toggledBarStyled,
    },
    ...sx,
  };
  return (
    <>
      {children || (
        <AspectRatio onClick={onClick} sx={hamburgerStyled}>
          <Box sx={innerSx}>
            <Box as="span" />
            <Box as="span" />
            <Box as="span" />
            <Box as="span" />
          </Box>
        </AspectRatio>
      )}
    </>
  );
};

Hamburger.propTypes = {
  children: PropTypes.node,
  innerSx: PropTypes.shape({}),
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
  toggled: PropTypes.bool,
};

Hamburger.defaultProps = {
  children: null,
  innerSx: {},
  onClick: null,
  sx: {},
  toggled: false,
};

export default Hamburger;
