import { createGlobalStyle } from "styled-components";

export const SCHOOL_MOBILE_NUMBER = { CONTACT_NUMBER: "+91 6354774692" };
export const SCHOOL_LOCATION = {
  LOCATION: `Bhesan Sewage Treatment Plant, Near Skyleaf Building, Bhesan Road, Surat - 395005`,
};
export const SCHOOL_EMAIL = {
  CONTACT: "nishargpathsala@gmail.com",
};

export const Banner_Slide_Setting = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  dotsClass: "button__bar",
};

export const BannerSliderGlobalStyled = createGlobalStyle`
.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  border-radius: 40px;
  text-align: center;
  position:absolute;
  bottom:20px;
  left:45%;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: ${(p) => p.theme.colors.white};
  color: transparent;
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
}

.button__bar li.slick-active button {
  height: 10px;
  width: 24px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.colors.primary};
}
`;

export const Event_Slider_Setting = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  dotsClass: "button__bar_event",

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const EventSliderGlobalStyled = createGlobalStyle`
.button__bar_event {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  border-radius: 40px;
  text-align: end;
}

.button__bar_event  li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar_event  li button {
  border: none;
  background: ${(p) => p.theme.colors.primary};
  color: transparent;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 100%;
}


.button__bar_event  li.slick-active button {
  height: 8px;
  width: 24px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.black};
}
`;

export const SUBJECTS = [
  {
    label: "Jobs",
    value: "Nisarg Pathshala - Contact for Jobs",
  },
  {
    label: "Partnership",
    value: "Nisarg Pathshala - Contact for Partnership",
  },
  {
    label: "Other",
    value: "Nisarg Pathshala - Contact for Other",
  },
];
export const CLASS = [
  {
    label: "class 1-3",
    value: "class1-3",
  },
  {
    label: "class 4-6",
    value: "class4-6",
  },
  {
    label: "class 7-9",
    value: "class7-9",
  },
  {
    label: "class 10",
    value: "class10",
  },
];
export const SOURCES = [
  {
    label: "Social Media",
    value: "Social Media",
  },
  {
    label: "Family/Friends",
    value: "Family/Friends",
  },
  {
    label: "Website",
    value: "Website",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const CONTACT_EMAIL_DEFAULT_VALUES = {
  EMAIL_SUBJECT: "Nisarg Pathshala - Contact Us",
};
export const COMPANY_LOCATION = { SURAT: "Surat, Gujarat, India" };

export const PAGE_ID = {
  ABOUT_PAGE: "aboutPage",
  BLOGS_PAGE: "blogsPage",
  CAREER_PAGE: "careerPage",
  CONTACT_PAGE: "contactPage",
  INDEX_PAGE: "indexPage",
  SCHOOLLIFE_PAGE: "schoollifePage",
  ADMISSION_PAGE: "admissionPage",
  CLASSROOM_PAGE: "classroomPage",
  STAFF_PAGE: "staffPage",
  EVENTS_PAGE: "eventsPage",
  TERMS_PAGE: "termsPage",
  GALLERY_PAGE: "galleryPage",
};
