import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Location } from "@reach/router";
import Box from "../Box";
import LinkTo from "../LinkTo";
import Image from "../Image";
import Icon from "../Icon";
import IsLinkMatchPath from "../../utilities/is-link-match-path";

import { languageContext } from "../../context/GlobalContextProvider";

const NavBar = ({ append, ignore, navLinks, prepend, sx }) => {
  const { toggleLanguage } = useContext(languageContext);

  const [openLinks, setOpenLinks] = useState([]);

  const toggleOpenIndex = (linkIndex) => {
    if (openLinks.includes(linkIndex)) {
      setOpenLinks((prevState) =>
        prevState.filter((l) => {
          return !(l.includes(`${linkIndex}_`) || l === linkIndex);
        })
      );
    } else {
      setOpenLinks((prevState) => [...prevState, linkIndex]);
    }
  };

  const setIconAndLabel = (icon, label) => (
    <Box sx={{ cursor: "pointer", display: "flex" }}>
      {icon && (
        <Icon
          className="nav-icon"
          svg={icon}
          sx={{
            "&> *": { height: "100%", width: "100%" },
            alignItems: "center",
            // color: "green",
            display: "flex",
            mr: 1,
            width: "1rem",
          }}
        />
      )}
      <Box as="span">{label}</Box>
    </Box>
  );
  const setNavGroup = (linkList, parentIndex) => (
    <>
      <Location>
        {({ location }) => (
          <Box as="ul" className={parentIndex ? "submenu" : ""}>
            {linkList &&
              linkList.length > 0 &&
              linkList.map((page, index) => {
                const hasSubmenu = page.submenu && page.submenu.length > 0;
                const indexOne = index + 1;
                const linkIndex = `${
                  parentIndex ? `${parentIndex}_${indexOne}` : indexOne
                }`;
                const isOpen = openLinks.includes(linkIndex);
                const isActiveParent =
                  hasSubmenu &&
                  page.submenu.some((i) => IsLinkMatchPath(location, i.path));
                const isActive =
                  IsLinkMatchPath(location, page.path) || isActiveParent;
                const level = linkIndex.split("_").length;
                return !ignore.includes(page.label) ? (
                  <Box
                    key={`nav-page-${page.label}`}
                    as="li"
                    className={`${linkIndex} ${
                      isOpen ? "open" : ""
                    } level-${level} ${hasSubmenu ? "has-submenu" : ""} ${
                      isActive ? "active" : ""
                    }`}
                    display="flex"
                  >
                    {page.action ? (
                      <Box 
                        as="a" 
                        onClick={() => page.action()} 
                        sx={{":hover .toggle-control": {
                          transform: "rotate(180deg)",
                          mb: 0,
                          transition: "transform .55s",
                        }}}>
                        {setIconAndLabel(page.icon, page.label)}
                        {hasSubmenu && <Image
                          className="toggle-control"
                          onClick={() => toggleOpenIndex(linkIndex)}
                          svgIcon="chevron-down"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "0.8rem",
                            ml: 1, 
                            mb: 1,
                            right: 1,
                            top: "0.7rem",
                            width: "0.8rem",
                            zIndex: 5,
                          }}
                        />}
                      </Box>
                    ) : (
                      <LinkTo to={page.path} sx={{":hover .toggle-control": {
                        transform: "rotate(180deg)",
                        mb: 0,
                        transition: "transform .55s",
                      }}}>
                        {setIconAndLabel(page.icon, page.label)}
                        {hasSubmenu && <Image
                          className="toggle-control"
                          onClick={() => toggleOpenIndex(linkIndex)}
                          svgIcon="chevron-down"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "0.8rem",
                            ml: 1, 
                            right: 1,
                            top: "0.7rem",
                            width: "0.8rem",
                            zIndex: 5,
                          }}
                        />}
                      </LinkTo>
                    )}
                    {hasSubmenu && (
                      <>
                        {/* <Image
                          className="toggle-control"
                          onClick={() => toggleOpenIndex(linkIndex)}
                          svgIcon="chevron-down"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "0.8rem",
                            mr: [3, null, null, 0],
                            position: "absolute",
                            right: 1,
                            top: "0.7rem",
                            width: "0.8rem",
                            zIndex: 5,
                          }}
                        /> */}
                        {setNavGroup(page.submenu, linkIndex)}
                      </>
                    )}
                  </Box>
                ) : null;
              })}
          </Box>
        )}
      </Location>
    </>
  );
  return (
    <>
      <Box as="nav" sx={sx}>
        {prepend}
        {setNavGroup(navLinks)}
        {append}
      </Box>

      <Box as="label" className="toggle">
        <input type="checkbox" />
        <span className="slider"></span>
        <span
          className="labels"
          data-on="EN"
          data-off="GU"
          onClick={toggleLanguage}
        ></span>
      </Box>
    </>
  );
};

NavBar.propTypes = {
  activeLinkSx: PropTypes.shape({}),
  append: PropTypes.node,
  ignore: PropTypes.arrayOf(PropTypes.string),
  linkSx: PropTypes.shape({}),
  menuSx: PropTypes.shape({}),
  navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prepend: PropTypes.node,
  sx: PropTypes.shape({}),
};

NavBar.defaultProps = {
  activeLinkSx: {},
  append: null,
  ignore: [],
  linkSx: {},
  menuSx: {},
  prepend: null,
  sx: {},
};

export default NavBar;
