exports.components = {
  "component---src-pages-default-404-js": () => import("./../../../src/pages/default/404.js" /* webpackChunkName: "component---src-pages-default-404-js" */),
  "component---src-pages-default-about-js": () => import("./../../../src/pages/default/about.js" /* webpackChunkName: "component---src-pages-default-about-js" */),
  "component---src-pages-default-admission-js": () => import("./../../../src/pages/default/admission.js" /* webpackChunkName: "component---src-pages-default-admission-js" */),
  "component---src-pages-default-career-js": () => import("./../../../src/pages/default/career.js" /* webpackChunkName: "component---src-pages-default-career-js" */),
  "component---src-pages-default-classroom-js": () => import("./../../../src/pages/default/classroom.js" /* webpackChunkName: "component---src-pages-default-classroom-js" */),
  "component---src-pages-default-classroomdetails-js": () => import("./../../../src/pages/default/classroomdetails.js" /* webpackChunkName: "component---src-pages-default-classroomdetails-js" */),
  "component---src-pages-default-contact-js": () => import("./../../../src/pages/default/contact.js" /* webpackChunkName: "component---src-pages-default-contact-js" */),
  "component---src-pages-default-eventdetail-js": () => import("./../../../src/pages/default/eventdetail.js" /* webpackChunkName: "component---src-pages-default-eventdetail-js" */),
  "component---src-pages-default-events-js": () => import("./../../../src/pages/default/events.js" /* webpackChunkName: "component---src-pages-default-events-js" */),
  "component---src-pages-default-gallery-js": () => import("./../../../src/pages/default/gallery.js" /* webpackChunkName: "component---src-pages-default-gallery-js" */),
  "component---src-pages-default-index-js": () => import("./../../../src/pages/default/index.js" /* webpackChunkName: "component---src-pages-default-index-js" */),
  "component---src-pages-default-schoollife-js": () => import("./../../../src/pages/default/schoollife.js" /* webpackChunkName: "component---src-pages-default-schoollife-js" */),
  "component---src-pages-default-staff-js": () => import("./../../../src/pages/default/staff.js" /* webpackChunkName: "component---src-pages-default-staff-js" */),
  "component---src-pages-default-terms-conditions-js": () => import("./../../../src/pages/default/terms-conditions.js" /* webpackChunkName: "component---src-pages-default-terms-conditions-js" */)
}

