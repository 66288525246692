export const footerLinks = [
  { label: "Home", path: "/", submenu: null },
  { label: "About", path: "/about", submenu: null },
  { label: "Career", path: "/career", submenu: null },
  { label: "Admission", path: "/admission", submenu: null },
  { label: "Events", path: "/events", submenu: null },
  { label: "Contact Us", path: "/contact", submenu: null },
];

export const facultyMedia = [
  {
    icon: "share",
    link: "https://www.instagram.com/",
  },
  {
    icon: "twitter-color",
    link: "https://twitter.com/",
  },
  {
    icon: "facebook-color",
    link: "https://www.facebook.com/",
  },
  {
    icon: "linkedin-color",
    link: "http://linkedin.com/",
  },
  {
    icon: "instagram-color",
    link: "https://www.instagram.com/",
  },
];

export const socialMedia = [
  // {
  //   icon: "twitter",
  //   link: "https://twitter.com/",
  // },
  {
    icon: "facebook",
    link: "https://www.facebook.com/profile.php?id=100078072169940&mibextid=ZbWKwL",
  },
  // {
  //   icon: "linkedin",
  //   link: "http://linkedin.com/",
  // },
  {
    icon: "instagram",
    link: "https://www.instagram.com/nishargpathsala/?igshid=NGExMmI2YTkyZg%3D%3D",
  },
];

export const socialMediaBlogs = [
  {
    icon: "twitter",
    link: "https://twitter.com/",
  },
  {
    icon: "facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "instagram",
    link: "https://www.instagram.com/",
  },
  {
    icon: "twitter",
    link: "https://twitter.com/",
  },
  {
    icon: "linkedin",
    link: "https://www.linkedin.com/",
  },
];

export const pages = [
  { label: "Home", path: "/", submenu: null },
  { label: "About Us", path: "/about", submenu: null },
  {
    label: "Admission",
    path: "/admission",
    submenu: [
      { label: "Why choose us?", path: "/admission/?section=why-choose-us" },
      {
        label: "Admission process",
        path: "/admission/?section=admission-process",
      },
      { label: "Inquiry Form", path: "/admission/?section=inquiry-form" },
    ],
  },
  {
    label: "School Life",
    path: "/schoollife",
    submenu: [
      {
        label: "Open board System",
        path: "/schoollife/?section=open-board-system",
      },
      {
        label: "Starndards Information",
        path: "/schoollife/?section=starndards-information",
      },
      {
        label: "Learning at NSG",
        path: "/schoollife/?section=learning-at-NSG",
      },
    ],
  },
  { label: "Staff", path: "/staff", submenu: null },
  {
    label: "Events",
    path: "/events",
    submenu: [
      // { label: "Activities", path: "/activities" },
      { label: "Photos/Videos", path: "/gallery" },
    ],
  },
  {
    label: "Contact",
    path: "/contact",
    submenu: [
      {
        label: "Contact",
        path: "/contact",
      },
      {
        label: "Career",
        path: "/career",
      },
    ],
  },
];
